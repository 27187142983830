
import {computed, defineComponent, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {key} from "@/store/index";
import useProduct from "@/composables/useProduct"
import topNav from '@/components/home/topNav.vue'
import bottomNav from '@/components/home/bottomNav.vue'

export default defineComponent({
  name: "SearchResult",
  components: {
    topNav,
    bottomNav,
  },
  setup() {
    const store = useStore(key);
    const {
      gotoDetailPage,
      get_product_count_by_category,
      get_product_count_by_sub_category,
      get_sub_categories,
      get_product_categories
    } = useProduct()
    const state = reactive({})

    const products = computed(() => store.getters['product/getSearchedProduct'])
    const categories = computed(() => store.getters['category/getCategories'])
    const productCategories = get_product_categories(categories.value, products.value)
    const subCategories = computed(() => store.getters['category/getSubCategories'])
    return {
      products,
      gotoDetailPage,
      get_sub_categories,
      get_product_count_by_category,
      get_product_count_by_sub_category,
      // categories,
      productCategories,
      subCategories,
      ...toRefs(state)
    }
  },
})
